import axios, { AxiosError } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { AppState } from '@/enums/AppState'
import { IResultFilters } from '@/interfaces/IFilters'
import { IGroup } from '@/interfaces/IGroup'
import { IUserSettings } from '@/interfaces/IUserSettings'
import router from '@/router'

class State {
    appState: AppState = AppState.INIT
}

const getters: GetterTree<State, any> = {
    appState(state) {
        return () => state.appState
    },
}

const mutations: MutationTree<State> = {
    setAppstate(state, payload: AppState) {
        state.appState = payload
    },
}

const actions: ActionTree<State, any> = {
    initializeApp({ commit, dispatch, state }) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['auth-type'] = ''
            if (state.appState === AppState.LOGGEDIN) {
                resolve(null)
            } else if (state.appState === AppState.LOGGEDOUT) {
                reject(null)
            } else {
                state.appState = AppState.LOADING

                dispatch('loginUser')
                    .then(() => {
                        if (localStorage.getItem('redirectUrl')) {
                            dispatch('goToFeedbackPortal')
                            localStorage.removeItem('redirectUrl')
                        }

                        dispatch('getUserSettings')
                            .then((settings: IUserSettings) => {
                                dispatch('getGroups')
                                    .then((groups: IGroup[]) => {
                                        if (groups.length === 0) {
                                            router.push({ name: 'Error', query: { msg: '10' } })
                                        }

                                        const group = groups.find((group) => group.id === settings.groep) ?? groups[0]
                                        commit('setSelectedGroup', group)

                                        dispatch('getVerwerkersOvereenkomst')
                                        dispatch('getVakLabels')
                                        dispatch('getJaargroepenLabels')
                                        dispatch('getAlleJaargroepenLabels')
                                        dispatch('getNoteSubjects')
                                        dispatch('getNoteCandidates')
                                        dispatch('getAllNoteLeerlingen')
                                        dispatch('getAllTags')
                                        dispatch('getRecommendedTags')
                                        dispatch('getTeachers')
                                        dispatch('getGroupSettings')

                                        const filters: IResultFilters = {
                                            TimespanFilter: settings.periodeFilters.oefeningPeriode,
                                            YeargroupFilter: settings.jaargroep,
                                            ToetsTimespanFilter: settings.periodeFilters.toetsPeriode,
                                            VolgenTimespanFilter: settings.periodeFilters.volgtoetsperiode,
                                            SociaalTimespanFilter: settings.periodeFilters.sociaalPeriode,
                                            CompetentiesTimespanFilter: settings.periodeFilters.competentiePeriode,
                                            MoeilijksteOefeningenTimespanFilter: settings.periodeFilters.oefeningPeriode,
                                        }

                                        commit('setFiltersCategoryDetail', filters)
                                        commit('setFiltersCandidateDetail', filters)
                                        commit('setFilters', filters)

                                        state.appState = AppState.LOGGEDIN

                                        dispatch('getRegio').then(() => resolve(null))
                                    })
                                    .catch((e) => {
                                        console.error(e)
                                        state.appState = AppState.ERROR
                                        reject(null)
                                    })
                            })
                            .catch((e) => {
                                console.error(e)
                                state.appState = AppState.LOGGEDOUT
                                reject(null)
                            })
                    })
                    .catch((e) => {
                        console.error(e)
                        state.appState = AppState.ERROR
                        reject(null)
                    })
            }
        })
    },
    initializeWidgets({ commit, dispatch, state, rootState }) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['auth-type'] = 'Moo'
            if (state.appState === AppState.WIDGETS) {
                resolve(null)
            } else {
                state.appState = AppState.LOADING
                dispatch('loginUser', null, { root: true })
                    .then(() => {
                        dispatch('getUserSettings').then((settings: IUserSettings) => {
                            dispatch('getGroups').then((groups: IGroup[]) => {
                                const group = groups.find((group) => group.id === settings.groep) ?? groups[0]
                                commit('setSelectedGroup', group)
                                dispatch('getVakLabels')

                                state.appState = AppState.WIDGETS
                                resolve(null)
                            })
                        })
                    })
                    .catch((e: AxiosError) => {
                        state.appState = AppState.LOGGEDOUT
                        reject(e)
                    })
            }
        })
    },
}

export default {
    state: new State(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
