import axios, { AxiosResponse } from 'axios'

import { ILesActiviteit } from '@/interfaces/ILesActiviteit'

import { IExtendedNoteInfo, IGroupedMentionUsers, INote, INoteWithLabel } from './../interfaces/INote'

export default class NotesService {
    getNotes(stamgroepId: number): Promise<any> {
        return axios.get(`/api/Frontend/Notities/${stamgroepId}`)
    }

    getNotesTwoWeeks(stamgroepId: number): Promise<any> {
        return axios.get(`/api/Frontend/Notities/${stamgroepId}/LaatsteTweeWeken`)
    }

    addNote(stamgroepId: number, note: INoteWithLabel) {
        return axios.post(`/api/Frontend/Notities/${stamgroepId}`, note)
    }

    updateNote(stamgroepId: number, note: INote) {
        return axios.put(`/api/Frontend/Notities/${stamgroepId}/${note.id}`, note)
    }

    deleteNote(stamgroepId: number, noteId: number): Promise<any> {
        return axios.delete(`/api/Frontend/Notities/${stamgroepId}/${noteId}`)
    }

    deleteSharedNote(stamgroepId: number, noteId: number): Promise<any> {
        return axios.delete(`/api/Frontend/Notities/${stamgroepId}/VerwijderGedeeld/${noteId}`)
    }

    getNoteLeerlingen(stamgroepId: number): Promise<any> {
        return axios.get(`/api/Frontend/Notities/Leerlingen/${stamgroepId}`)
    }

    getAllNoteLeerlingen(): Promise<AxiosResponse<IGroupedMentionUsers[]>> {
        return axios.get<IGroupedMentionUsers[]>(`/api/Frontend/Notities/Leerlingen`)
    }

    getAllNoteTags(): Promise<any> {
        return axios.get(`/api/Frontend/Notities/Tags`)
    }

    getRecommendedNoteTags(stamgroepId: number): Promise<any> {
        return axios.get(`/api/Frontend/Notities/Tags/voorgesteld/${stamgroepId}`)
    }

    deleteRecommendedTag(stamgroepId: number, tagId: number): Promise<any> {
        return axios.delete(`/api/Frontend/Notities/Tags/${stamgroepId}/${tagId}`)
    }

    getNoteSubjects(): Promise<any> {
        return axios.get(`/api/Frontend/Notities/NotitieOnderwerpen`)
    }

    getRelevantNotes(
        stamgroepId: number,
        params: {
            skip: number
            take: number
            labelId: number
            afspraakId?: number
            activiteitIngeplandId?: number
        }
    ): Promise<any> {
        return axios.get(`/api/Frontend/Planning/${stamgroepId}/Notities/Relevant`, {
            params,
        })
    }

    copyNote(stamgroepId: number, notitieId: number, activiteitId: number): Promise<AxiosResponse<ILesActiviteit>> {
        return axios.get(`/api/Frontend/Notities/${stamgroepId}/Kopieer/${notitieId}/activiteit/${activiteitId}`)
    }

    copyNoteAfspraak(stamgroepId: number, notitieId: number, afspraakId: number): Promise<AxiosResponse<ILesActiviteit>> {
        return axios.get(`/api/Frontend/Notities/${stamgroepId}/Kopieer/${notitieId}/afspraak/${afspraakId}`)
    }

    getExtendedNoteInfo(noteId: number): Promise<AxiosResponse<IExtendedNoteInfo>> {
        return axios.get(`/api/Frontend/Notities/extendedInfo/${noteId}`)
    }
}

export const notesService: NotesService = new NotesService()
