<template>
    <Sidebar
        id="sidebar-backdrop"
        v-if="shouldShowSlideOut"
        v-model="sidebarOpen"
    >
        <template #sidebar-body>
            <CreateNote
                v-if="currentPage === NOTE_SIDEBAR_PAGES.CREATE_NOTE && !planOnly"
                @plan-note="onPlanNote"
            />
            <ScheduleNote
                v-else
                :default-tab="createNoteTab"
                @open-create-note="currentPage = NOTE_SIDEBAR_PAGES.CREATE_NOTE"
            />
        </template>
    </Sidebar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ScheduleType } from '@/components/Notes/ScheduleNote.vue'
import Sidebar from '@/components/Sidebar.vue'
import { useStore } from '@/composables/useStore'
import { AppState } from '@/enums/AppState'
import { ObjectValues } from '@/interfaces/IDay'
import { useRoute, useRouter } from 'vue-router'

import CreateNote from './CreateNote.vue'
import ScheduleNote from './ScheduleNote.vue'

const NOTE_SIDEBAR_PAGES = {
    CREATE_NOTE: 'create-note',
    SCHEDULE_NOTE: 'schedule-note',
} as const

type NoteSidebarPages = ObjectValues<typeof NOTE_SIDEBAR_PAGES>

const currentPage = ref<NoteSidebarPages>(NOTE_SIDEBAR_PAGES.CREATE_NOTE)
const createNoteTab = ref<ScheduleType>('Les')
const $route = useRoute()
const $router = useRouter()

const { appStore, notesStore } = useStore()

const shouldShowSlideOut = computed((): boolean => {
    return appStore.getters.value.appState === AppState.LOGGEDIN && !$route.path.startsWith('/login') && !$route.path.startsWith('/instellingen') && !$route.path.startsWith('/onboarding') && !$route.path.startsWith('/digibord')
})

const onSidebarHide = () => {
    currentPage.value = NOTE_SIDEBAR_PAGES.CREATE_NOTE
    notesStore.commits.setSidebarState(false)
    notesStore.commits.resetCurrentNote()
    notesStore.commits.resetCurrentNoteMoment()
}

const sidebarOpen = computed({
    get() {
        return notesStore.getters.value.getSidebarState
    },
    set(open: boolean) {
        if (open) {
            notesStore.commits.setSidebarState(open)
        } else {
            onSidebarHide()
        }
    },
})

const onPlanNote = (defaultTab: ScheduleType) => {
    createNoteTab.value = defaultTab
    currentPage.value = NOTE_SIDEBAR_PAGES.SCHEDULE_NOTE
}

const planOnly = computed(() => {
    return notesStore.getters.value.getSidebarPlanNoteOnly
})
</script>
