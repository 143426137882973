import axios, { AxiosResponse } from 'axios'

import { IActiviteitDetailsMetLesId } from '@/interfaces/IActiviteitDetails'
import { IAfspraakPost, ILesMovePost, ILesReeksMovePost, IPlanItem, IPlanItemIngepland, IPlanItemPost } from './../interfaces/IPlanning'

export default class PlanningService {
    calculatePlanning(groepId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/Calculate/${groepId}`)
    }
    calculatePlanningLes(groepId: number, activiteitId: number, lesId: number, templateId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/Calculate/${groepId}/${activiteitId}/${lesId}/${templateId}`)
    }
    calculatePlanningLesAction(actie: number, groepId: number, activiteitId: number, lesId: number | null, templateId: number | null) {
        return axios.get(`/api/Frontend/Jaarplanning/Calculate/${actie}/${groepId}/${activiteitId}`, {
            params: {
                lesId: lesId,
                lesPlanningTemplateId: templateId,
            },
        })
    }
    getMethodeblokken(groepId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/methodeBLokken/${groepId}`)
    }
    getTemplatesEnLessen(groepId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/Afspraken/TemplatesAndLessen/${groepId}`)
    }
    getPlanning(groepId: number, begin?: string, eind?: string) {
        return axios.get(`/api/Frontend/Jaarplanning/${groepId}`, {
            params: {
                begin: begin ? begin : undefined,
                eind: eind ? eind : undefined,
            },
        })
    }
    getSchoolweken() {
        return axios.get(`/api/Frontend/Jaarplanning/Schoolweken`)
    }
    getIngeplandeItems(groepId: number, begin?: string, eind?: string) {
        return axios.get(`/api/Frontend/Jaarplanning/IngeplandeItems/${groepId}`, {
            params: {
                begin: begin ? begin : undefined,
                eind: eind ? eind : undefined,
            },
        })
    }
    getActiviteitDetails(activiteitId: number, stamgroepId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/GetActiviteitDetails/${activiteitId}/${stamgroepId}`)
    }
    async getActiviteitDetailsList(activiteitIds: number[], stamgroepId: number): Promise<IActiviteitDetailsMetLesId[]> {
        const response = await axios.get(`/api/Frontend/Jaarplanning/ActiviteitDetailsList/${stamgroepId}`, {
            params: {
                lesIds: activiteitIds,
            },
            paramsSerializer: {
                indexes: null,
            },
        })

        return response.data
    }
    getLabelColors() {
        return axios.get(`/api/Frontend/Labels/Vakgebieden/kleuren`)
    }
    postPlanItem(groepId: number, jaargroepId: number, planitemId: number, payload: IPlanItemPost) {
        return axios.post(`/api/Frontend/Jaarplanning/${groepId}/${jaargroepId}/${planitemId}`, payload)
    }
    postEnkelelesDoorschuiven(payload: ILesMovePost) {
        return axios.post(`/api/Frontend/Jaarplanning/EnkeleLesDoorschuiven`, payload)
    }
    postLesreeksDoorschuiven(payload: ILesReeksMovePost) {
        return axios.post(`/api/Frontend/Jaarplanning/LesreeksDoorschuiven`, payload)
    }
    postPlanItemCorrigeren(stamgroepId: string, ingeplandeActiviteitId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/LatenVervallenEnAlsEerstvolgendeInplannen/${stamgroepId}/${ingeplandeActiviteitId}`)
    }
    postAfspraak(groepId: number, payload: IAfspraakPost): Promise<AxiosResponse<IPlanItemIngepland>> {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
            let value = (payload as any)[key]
            value === null ? (value = '') : null
            formData.append(key, value)
        })
        return axios.post(`/api/Frontend/Jaarplanning/Afspraken/${groepId}`, formData)
    }
    putAfspraak(groepId: number, payload: IAfspraakPost) {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
            let value = (payload as any)[key]
            value === null ? (value = '') : null
            formData.append(key, value)
        })
        return axios.put(`/api/Frontend/Jaarplanning/Afspraken/${groepId}`, formData)
    }
    deleteLes(groepId: number, lesId: number) {
        return axios.delete(`/api/Frontend/Jaarplanning/${groepId}/${lesId}`)
    }
    methodeAfbeelding(methodeId: number) {
        return axios.get(`/api/Frontend/Jaarplanning/Afspraken/MethodeAfbeelding/${methodeId}`).then((data) => data.data)
    }
    deleteAfspraak(groepId: number, afspraakId: number) {
        return axios.delete(`/api/Frontend/Jaarplanning/Afspraken/${groepId}/${afspraakId}`)
    }
    deleteAfspraakAfbeelding(groepId: number, afspraakId: number) {
        return axios.delete(`/api/Frontend/Jaarplanning/Afspraken/${groepId}/${afspraakId}/afbeelding`)
    }
    getLabelsMetNotities(stamgroepId: number) {
        return axios.get(`/api/Frontend/Planning/${stamgroepId}/notities/vakgerelateerd`)
    }
    getToekomstigeLessenVoorVak(stamgroepId: number, vakId: number): Promise<AxiosResponse<IPlanItem[]>> {
        return axios.get(`/api/Frontend/Jaarplanning/${stamgroepId}/toekomst/${vakId}`)
    }
}

export const planningService = new PlanningService()
